// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
:root {
  --my-fourth-palette-primary: #ff00ff;
  --my-fourth-palette-accent: #00ffff;
  --my-fourth-palette-warn: #ff0000;
}
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$md-primary: (
  50: #eaeaea,
  100: #cacaca,
  200: #a6a7a6,
  300: #828382,
  400: #686968,
  500: #4d4e4d,
  600: #464746,
  700: #3d3d3d,
  800: #343534,
  900: #252525,
  A100: #f18282,
  A200: #ec5454,
  A400: #ff0e0e,
  A700: #f30000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-accent: (
  50: #e2e3e5,
  100: #b7babf,
  200: #878c94,
  300: #575d69,
  400: #333b49,
  500: #0f1829,
  600: #0d1524,
  700: #0b111f,
  800: #080e19,
  900: #04080f,
  A100: #528bff,
  A200: #1f69ff,
  A400: #004eeb,
  A700: #0046d1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-warn: (
  50: #fbe5e5,
  100: #f5bebe,
  200: #ee9393,
  300: #e76767,
  400: #e14747,
  500: #dc2626,
  600: #d82222,
  700: #d31c1c,
  800: #ce1717,
  900: #c50d0d,
  A100: #fff1f1,
  A200: #ffbebe,
  A400: #ff8b8b,
  A700: #ff7272,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$urbanfront-primary: mat.define-palette($md-primary);
$urbanfront-accent: mat.define-palette($md-accent);

// The warn palette is optional (defaults to red).
$urbanfront-warn: mat.define-palette($md-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$urbanfront-theme: mat.define-light-theme(
  (
    color: (
      primary: $urbanfront-primary,
      accent: $urbanfront-accent,
      warn: $urbanfront-warn,
      info: $urbanfront-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($urbanfront-theme);

/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .btn-icon-tooltip {
    @apply uppercase text-xs;
  }
  .badge {
    @apply absolute inline-flex items-center justify-center w-2.5 h-2.5 text-xs font-bold text-white rounded-full -top-2 -right-2 animate-pulse ;
  }
  .chip-success{
    @apply bg-green-100 text-center text-green-800 text-xs font-medium  px-2.5 py-0.5 rounded border border-green-400
  }
  .chip-error{
    @apply bg-red-100 text-center text-red-800 text-xs font-medium  px-2.5 py-0.5 rounded border border-red-400
  }
  .chip-warning{
    @apply bg-yellow-100 text-center text-yellow-800 text-xs font-medium  px-2.5 py-0.5 rounded border border-yellow-400
  }
}

.spacer {
  flex: 1 1 auto;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
